import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React, { useCallback } from "react";
import UIkit from "uikit";

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  img[data-src][src*="data:image"] {
    background: rgba(0, 0, 0, 0.1);
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }
`;

const BannerHalf = ({ input }) => {
  const img = useCallback(
    node => {
      if (node !== null) {
        UIkit.img(node, {
          dataSrc: input.primary.image.url,
          ...input.primary.image.dimensions
        });
      }
    },
    [input.primary.image.dimensions, input.primary.image.url]
  );

  return (
    <Content
      className="uk-child-width-1-2@s uk-grid uk-flex-middle"
      data-uk-grid
    >
      <div className="uk-text-center">
        <img ref={img} alt={input.primary.image.alt} />
      </div>
      <div className="uk-text-center">
        <RichText render={input.primary.text} />
      </div>
    </Content>
  );
};

export default BannerHalf;
