import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React from "react";
import tw from "tailwind.macro";

const BlockQuote = styled.blockquote`
  ${tw`border-0 border-l-8 border-gray-600 border-solid pl-8 my-12`};
  max-width: ${(props) => props.theme.maxWidthText};
`;

const Quote = ({ input }) => {
  return <BlockQuote>{RichText.render(input.primary.quote)}</BlockQuote>;
};

export default Quote;
