import { ClassNames } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";

const Scroller = styled.div`
  width: 3px;
  height: 7px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
  }
  @keyframes scroll {
  0% { opacity: 0; }
  20% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(8px); opacity: 0;}
  }
`;

const Anchor = styled.a`
  display: none;
  position: relative;

  @media only screen and (min-width: 768px) {
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
  }
`;

const ScrollTo = ({ href, className }) => (
  <ClassNames>
    {({ cx }) => (
      <Anchor
        href={href}
        className={cx("uk-text-center", className)}
        data-uk-scroll
        data-uk-parallax="y:-50; opacity: 1,0; viewport: 0.7"
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            margin: "70px auto",
            width: "34px",
            height: "55px",
          }}
        >
          <div
            style={{
              width: "3px",
              padding: "5px 8px",
              height: "25px",
              border: "2px solid #fff",
              borderRadius: "15px",
              opacity: "0.75",
              boxSizing: "content-box",
            }}
          >
            <Scroller />
          </div>
        </div>
      </Anchor>
    )}
  </ClassNames>
);

export default ScrollTo;
