import React from "react";

import { BannerFull, BannerHalf, BodyText, CodeBlock, Quote } from "../slices";

const SliceZone = ({ allSlices }) => {
  if (!allSlices) return null;

  const slice = allSlices.map((s, index) => {
    switch (s.type) {
      case "text":
        return <BodyText key={index.toString()} input={s} />;
      case "code_block":
        return <CodeBlock key={index.toString()} input={s} />;
      case "banner_full":
        return <BannerFull key={index.toString()} input={s} />;
      case "banner_half":
        return <BannerHalf key={index.toString()} input={s} />;
      case "quote":
        return <Quote key={index.toString()} input={s} />;
      default:
        return null;
    }
  });

  return slice;
};

export default SliceZone;
