import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import React, { Component } from "react";

export default class Categories extends Component {
  render() {
    const { categories } = this.props;
    return (
      <>
        {categories.map((cat, i) => (
          <React.Fragment key={cat}>
            {!!i && ", "}
            <Link to={`/categories/${kebabCase(cat)}`}>{cat}</Link>
          </React.Fragment>
        ))}
      </>
    );
  }
}
