import { RichText } from "prismic-reactjs";

const Text = ({ pure, children }) => {
  if (!children) return null;

  if (pure) {
    return RichText.asText(children);
  }

  return RichText.render(children);
};

export default Text;
