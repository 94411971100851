import React from "react";

const Illustration = ({ image }) => {
  if (!image) {
    return null;
  }

  const mainView = image;
  const tabletView = image.tablet;
  const mobileView = image.mobile;
  const dimensions = mainView.dimensions && {
    height: mainView.dimensions.height,
    width: mainView.dimensions.width,
  };

  return (
    <picture>
      {mobileView && (
        <source media="(max-width: 400px)" srcSet={mobileView.url} />
      )}
      {tabletView && (
        <source media="(max-width: 900px)" srcSet={tabletView.url} />
      )}

      <source srcSet={mainView.url} />
      <img src={mainView.url} alt={mainView.alt} {...dimensions} />
    </picture>
  );
};

export default Illustration;
