import styled from "@emotion/styled";
import React, { useCallback } from "react";
import UIkit from "uikit";

const Img = styled.img`
  &[data-src][src*="data:image"] {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Image = ({ alt, url, dimensions, ...props }) => {
  const img = useCallback(
    (node) => {
      UIkit.img(node, {
        dataSrc: url,
        ...dimensions,
      });
    },
    [dimensions, url]
  );

  return <Img ref={img} alt={alt} {...props} />;
};

export default Image;

Image.defaultProps = {
  alt: "",
};
