import styled from "@emotion/styled";
import Img from "gatsby-image";
import React from "react";

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const Image = ({ input }) => (
  <Content>
    <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
  </Content>
);

export default Image;
