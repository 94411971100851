import { Image } from "@/components";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import React from "react";

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const Banner = ({ input }) => (
  <Content>
    <Image {...input.primary.image} />
    <RichText render={input.primary.text} />
  </Content>
);

export default Banner;
