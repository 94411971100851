import { ClassNames } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";

const Container = styled.div`
  min-height: 480px;
  position: relative;
  ${"" /* background-size: 65vw; */}
  background-repeat: no-repeat;

  ${(props) =>
    props.background &&
    `background-image: url(${props.background.src});
  background-position: ${props.background.position};
  background-size: ${props.background.size};`}

  ${"" /* @media only screen and (min-width: 768px) {
    background-size: auto 95%;
  } */}
`;

const Section = ({ children, classNames, ...props }) => (
  <ClassNames>
    {({ cx }) => (
      <Container
        {...props}
        className={cx("uk-section", classNames)}
        data-uk-parallax="bgy: -200"
      >
        {children}
      </Container>
    )}
  </ClassNames>
);

export default Section;
